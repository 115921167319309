'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Events = function () {
    function Events() {
        _classCallCheck(this, Events);
        this.events = {};
        this.videoEvents = [
            'abort',
            'canplay',
            'canplaythrough',
            'durationchange',
            'emptied',
            'ended',
            'error',
            'loadeddata',
            'loadedmetadata',
            'loadstart',
            'mozaudioavailable',
            'pause',
            'play',
            'playing',
            'progress',
            'ratechange',
            'seeked',
            'seeking',
            'stalled',
            'suspend',
            'timeupdate',
            'volumechange',
            'waiting'
        ];
        this.playerEvents = [
            'screenshot',
            'thumbnails_show',
            'thumbnails_hide',
            'danmaku_show',
            'danmaku_hide',
            'danmaku_clear',
            'danmaku_loaded',
            'danmaku_send',
            'danmaku_opacity',
            'contextmenu_show',
            'contextmenu_hide',
            'notice_show',
            'notice_hide',
            'quality_start',
            'quality_end',
            'destroy',
            'resize',
            'fullscreen',
            'fullscreen_cancel',
            'webfullscreen',
            'webfullscreen_cancel',
            'subtitle_show',
            'subtitle_hide',
            'subtitle_change',
            'stats',
            'peerId',
            'peers'
        ];
    }
    _createClass(Events, [
        {
            key: 'on',
            value: function on(name, callback) {
                if (this.type(name) && typeof callback === 'function') {
                    if (!this.events[name]) {
                        this.events[name] = [];
                    }
                    this.events[name].push(callback);
                }
            }
        },
        {
            key: 'trigger',
            value: function trigger(name, info) {
                if (this.events[name] && this.events[name].length) {
                    for (var i = 0; i < this.events[name].length; i++) {
                        this.events[name][i](info);
                    }
                }
            }
        },
        {
            key: 'type',
            value: function type(name) {
                if (this.playerEvents.indexOf(name) !== -1) {
                    return 'player';
                } else if (this.videoEvents.indexOf(name) !== -1) {
                    return 'video';
                }
                console.error('Unknown event name: ' + name);
                return null;
            }
        }
    ]);
    return Events;
}();
exports.default = Events;