'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _icons = require('./icons');
var _icons2 = _interopRequireDefault(_icons);
var _player = require('../template/player.art');
var _player2 = _interopRequireDefault(_player);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Template = function () {
    function Template(options) {
        _classCallCheck(this, Template);
        this.container = options.container;
        this.options = options.options;
        this.index = options.index;
        this.tran = options.tran;
        this.init();
    }
    _createClass(Template, [{
            key: 'init',
            value: function init() {
                this.container.innerHTML = (0, _player2.default)({
                    options: this.options,
                    index: this.index,
                    tran: this.tran,
                    icons: _icons2.default,
                    video: {
                        current: true,
                        pic: this.options.video.pic,
                        screenshot: this.options.screenshot,
                        preload: this.options.preload,
                        url: this.options.video.url,
                        subtitle: this.options.subtitle
                    }
                });
                this.volumeBar = this.container.querySelector('.dplayer-volume-bar-inner');
                this.volumeBarWrap = this.container.querySelector('.dplayer-volume-bar');
                this.volumeBarWrapWrap = this.container.querySelector('.dplayer-volume-bar-wrap');
                this.volumeButton = this.container.querySelector('.dplayer-volume');
                this.volumeButtonIcon = this.container.querySelector('.dplayer-volume-icon');
                this.volumeIcon = this.container.querySelector('.dplayer-volume-icon .dplayer-icon-content');
                this.playedBar = this.container.querySelector('.dplayer-played');
                this.loadedBar = this.container.querySelector('.dplayer-loaded');
                this.playedBarWrap = this.container.querySelector('.dplayer-bar-wrap');
                this.playedBarTime = this.container.querySelector('.dplayer-bar-time');
                this.danmaku = this.container.querySelector('.dplayer-danmaku');
                this.danmakuLoading = this.container.querySelector('.dplayer-danloading');
                this.video = this.container.querySelector('.dplayer-video-current');
                this.bezel = this.container.querySelector('.dplayer-bezel-icon');
                this.playButton = this.container.querySelector('.dplayer-play-icon');
                this.videoWrap = this.container.querySelector('.dplayer-video-wrap');
                this.controllerMask = this.container.querySelector('.dplayer-controller-mask');
                this.ptime = this.container.querySelector('.dplayer-ptime');
                this.settingButton = this.container.querySelector('.dplayer-setting-icon');
                this.settingBox = this.container.querySelector('.dplayer-setting-box');
                this.mask = this.container.querySelector('.dplayer-mask');
                this.loop = this.container.querySelector('.dplayer-setting-loop');
                this.loopToggle = this.container.querySelector('.dplayer-setting-loop .dplayer-toggle-setting-input');
                this.showDanmaku = this.container.querySelector('.dplayer-setting-showdan');
                this.showDanmakuToggle = this.container.querySelector('.dplayer-showdan-setting-input');
                this.unlimitDanmaku = this.container.querySelector('.dplayer-setting-danunlimit');
                this.unlimitDanmakuToggle = this.container.querySelector('.dplayer-danunlimit-setting-input');
                this.speed = this.container.querySelector('.dplayer-setting-speed');
                this.speedItem = this.container.querySelectorAll('.dplayer-setting-speed-item');
                this.danmakuOpacityBar = this.container.querySelector('.dplayer-danmaku-bar-inner');
                this.danmakuOpacityBarWrap = this.container.querySelector('.dplayer-danmaku-bar');
                this.danmakuOpacityBarWrapWrap = this.container.querySelector('.dplayer-danmaku-bar-wrap');
                this.danmakuOpacityBox = this.container.querySelector('.dplayer-setting-danmaku');
                this.dtime = this.container.querySelector('.dplayer-dtime');
                this.controller = this.container.querySelector('.dplayer-controller');
                this.commentInput = this.container.querySelector('.dplayer-comment-input');
                this.commentButton = this.container.querySelector('.dplayer-comment-icon');
                this.commentSettingBox = this.container.querySelector('.dplayer-comment-setting-box');
                this.commentSettingButton = this.container.querySelector('.dplayer-comment-setting-icon');
                this.commentSettingFill = this.container.querySelector('.dplayer-comment-setting-icon path');
                this.commentSendButton = this.container.querySelector('.dplayer-send-icon');
                this.commentSendFill = this.container.querySelector('.dplayer-send-icon path');
                this.commentColorSettingBox = this.container.querySelector('.dplayer-comment-setting-color');
                this.browserFullButton = this.container.querySelector('.dplayer-full-icon');
                this.webFullButton = this.container.querySelector('.dplayer-full-in-icon');
                this.menu = this.container.querySelector('.dplayer-menu');
                this.menuItem = this.container.querySelectorAll('.dplayer-menu-item');
                this.qualityList = this.container.querySelector('.dplayer-quality-list');
                this.camareButton = this.container.querySelector('.dplayer-camera-icon');
                this.subtitleButton = this.container.querySelector('.dplayer-subtitle-icon');
                this.subtitleButtonInner = this.container.querySelector('.dplayer-subtitle-icon .dplayer-icon-content');
                this.subtitle = this.container.querySelector('.dplayer-subtitle');
                this.qualityButton = this.container.querySelector('.dplayer-quality-icon');
                this.barPreview = this.container.querySelector('.dplayer-bar-preview');
                this.barWrap = this.container.querySelector('.dplayer-bar-wrap');
                this.notice = this.container.querySelector('.dplayer-notice');
                this.infoPanel = this.container.querySelector('.dplayer-info-panel');
                this.infoPanelClose = this.container.querySelector('.dplayer-info-panel-close');
                this.infoVersion = this.container.querySelector('.dplayer-info-panel-item-version .dplayer-info-panel-item-data');
                this.infoFPS = this.container.querySelector('.dplayer-info-panel-item-fps .dplayer-info-panel-item-data');
                this.infoType = this.container.querySelector('.dplayer-info-panel-item-type .dplayer-info-panel-item-data');
                this.infoUrl = this.container.querySelector('.dplayer-info-panel-item-url .dplayer-info-panel-item-data');
                this.infoResolution = this.container.querySelector('.dplayer-info-panel-item-resolution .dplayer-info-panel-item-data');
                this.infoDuration = this.container.querySelector('.dplayer-info-panel-item-duration .dplayer-info-panel-item-data');
                this.infoDanmakuId = this.container.querySelector('.dplayer-info-panel-item-danmaku-id .dplayer-info-panel-item-data');
                this.infoDanmakuApi = this.container.querySelector('.dplayer-info-panel-item-danmaku-api .dplayer-info-panel-item-data');
                this.infoDanmakuAmount = this.container.querySelector('.dplayer-info-panel-item-danmaku-amount .dplayer-info-panel-item-data');
                this.infoP2pVersion = this.container.querySelector('.dplayer-info-panel-item-p2p-version .dplayer-info-panel-item-data');
                this.infoP2pDownloaded = this.container.querySelector('.dplayer-info-panel-item-p2p-downloaded .dplayer-info-panel-item-data');
                this.infoP2pUploaded = this.container.querySelector('.dplayer-info-panel-item-p2p-uploaded .dplayer-info-panel-item-data');
                this.infoPeers = this.container.querySelector('.dplayer-info-panel-item-peers .dplayer-info-panel-item-data');
                this.infoPeerid = this.container.querySelector('.dplayer-info-panel-item-peerid .dplayer-info-panel-item-data');
            }
        }]);
    return Template;
}();
exports.default = Template;